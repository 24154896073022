import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["onClick", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style['container'])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (row, indexRow) => {
      return (_openBlock(), _createElementBlock("div", {
        key: indexRow,
        class: _normalizeClass(_ctx.$style['container__row'])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (col, indexCol) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: `${indexRow}-${indexCol}`
          }, [
            (col.id)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (col.use)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        onClick: ($event)=> ($event.x !== 0 && $event.y !== 0) && _ctx.$emit('serviceDrawerClick', col),
                        class: _normalizeClass(["btn", _ctx.$style['cell__btn']])
                      }, " S ", 10, _hoisted_1))
                    : (col.use === 0)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 1,
                          onClick: ($event: any) => (_ctx.$emit('drawerClick', col)),
                          class: _normalizeClass(["btn", [_ctx.$style['cell__btn'], _ctx.$style[`cell__btn--${_ctx.mapStateToColor(col)}`]]]),
                          disabled: _ctx.block || col.status==='disabled' || (this.areColumnsPopulated && this.actualItem && _ctx.celldbibj && (this.actualItem.PatronBarcode !== _ctx.celldbibj.patronbarcode))
                        }, _toDisplayString(`${col.id}(${col.items.length}) ${(col.status === "full") ? `[${_ctx.localPrefs.MaxCheckOutLateDays - Math.min(...col.items.map((item) => item.days))}]` : ""}`), 11, _hoisted_2))
                      : _createCommentVNode("", true)
                ], 64))
              : (col.empty)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['row__cell'])
                  }, null, 2))
                : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ], 2))
    }), 128))
  ], 2))
}