import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_easy_spinner = _resolveComponent("easy-spinner")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style['container'])
  }, [
    _createVNode(_component_easy_spinner, {
      size: '100px',
      color: 'rgb(251, 133, 0)'
    }, null, 8, ["color"])
  ], 2))
}