import { createApp } from 'vue';
import App from './App.vue'
import { createI18n } from 'vue-i18n'
import store from './store'
import messages from "@/assets/translations.json";
import 'gitart-vue-dialog/dist/style.css'
import { GDialog } from 'gitart-vue-dialog'
import { Icon } from '@iconify/vue';
import easySpinner from 'vue-easy-spinner';
import SbSpinner from "@/components/sb-spinner/sb-spinner.vue";

const i18n = createI18n({
	locale: 'it', // set locale
	fallbackLocale: 'it', // set fallback locale
	messages, // set locale messages
	// If you need to specify other options, you can set other options
	// ...
})

const app = createApp(App);

app.component('GDialog', GDialog)
	.component('Icon', Icon)
	.use(easySpinner, {prefix: 'easy'})
	.component('sb-spinner', SbSpinner)
	.use(i18n)
	.use(store).mount('#app');
