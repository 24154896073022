import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sb_spinner = _resolveComponent("sb-spinner")!
  const _component_sb_nav_bar = _resolveComponent("sb-nav-bar")!
  const _component_sb_footer = _resolveComponent("sb-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_sb_spinner, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style['container'])
    }, [
      _createVNode(_component_sb_nav_bar, { title: 'Reso24' }),
      _createVNode(_component_sb_footer, null, {
        default: _withCtx(() => [
          _createTextVNode(" Comperio srl - Reso 24 © rel. " + _toDisplayString(_ctx.release) + " - " + _toDisplayString(_ctx.$t("message.hello")), 1)
        ]),
        _: 1
      })
    ], 2)
  ], 64))
}