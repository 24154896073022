import {defineComponent, PropType} from "vue";

export default defineComponent({
	name: "sb-drawers",
	props: {
		prefs: {
			type: Object,
			default: () => ({} as IPrefs)
		},
		columns: {
			type: Array as PropType<Array<Array<any>>>,
			default: () => []
		},
		actualItem: {
			type: Object,
			default: () => ({})
		},
		block: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		localPrefs: {},
		areColumnsPopulated: false,
	}),
	methods: {
		mapStateToColor(cellDBobj: any) {
			let color = "primary";
			switch (cellDBobj.status) {
				case "empty":
					color = "default";
					break;
				case "full":
					color = "primary";
					if (Math.min(...cellDBobj.items.map((item: any) => item.days)) > this.prefs.MaxCheckOutLateDays)
						color = "red";
					break;
				case "disabled":
					color = "grey";
					break;
				default:
					color = "default";
					break;
			}

			// console.log("this.actualItem", this.actualItem);
			// console.log("cellDBobj", cellDBobj);
			// console.log("this.areColumnsPopulated", this.areColumnsPopulated);

			// if (this.areColumnsPopulated && this.actualItem && cellDBobj
			// 	&& (this.actualItem.PatronBarcode !== cellDBobj.patronbarcode)) {
			// 	color = "grey";
			// }
			return color;
		},
	},
	mounted() {
		this.localPrefs = this.prefs;
	}
})
