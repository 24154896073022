import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"flex-wrap":"wrap","flex":"1 1 auto","min-width":"0","flex-direction":"row","flex-flow":"row wrap"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", {
    id: "cmp-footer",
    class: _normalizeClass([_ctx.$style['footer'], _ctx.$style['footer--bottom-fixed'], _ctx.$style['footer--justify-center']])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}