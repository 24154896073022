

console.log("app.vue");

import {defineComponent} from "vue";
import SmartLocker from "@/sections/cmp-smart-locker/cmp-smart-locker.vue";
import Reso24 from "@/sections/cmp-reso24/cmp-reso24.vue";
import Reso24Monitor from "@/sections/cmp-reso24-monitor/cmp-reso24-monitor.vue";

declare const settings: any;
declare const gui: any;

export default defineComponent({
	name: "app",
	computed: {
		currentSectionComponent: () => {
			// eslint-disable-next-line
			console.log("settings", settings);
			switch (settings.hardwaretype) {
				case 'smartlocker':
					return SmartLocker;
				case 'reso24':
					return Reso24;
				case 'reso24monitor':
					console.log("reso24monitor", Reso24Monitor)
					return Reso24Monitor;
			}
		}
	}
})
