import {defineComponent} from "vue";

export default defineComponent({
	name: "sb-carousel",
	props: {
		libraryName: {
			type: String,
			default: ""
		}
	},
	data: () => ({
		slidesNumber: []
	})
})
