import {defineComponent, PropType} from "vue";

export default defineComponent({
	name: "sb-nav-bar",
	props: {
		title: {
			type: String,
			default: ''
		},
	}
})
