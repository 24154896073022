import {defineComponent} from "vue";
import moment from "moment";

import SbFooter from "@/components/sb-footer/sb-footer.vue";
import SbCarousel from "@/components/sb-carousel/sb-carousel.vue";
import SbNavBar from "@/components/sb-nav-bar/sb-nav-bar.vue";
import SbDrawers from "@/sections/cmp-smart-locker/components/sb-drawers/sb-drawers.vue";
import {EnumLoanStatus} from "@/enums/EnumLoanStatus";

const logger = sb.logger;

export default defineComponent({
	name: "cmp-smart-locker",
	components: {
		"sb-footer": SbFooter,
		"sb-carousel": SbCarousel,
		"sb-nav-bar": SbNavBar,
		"sb-drawers": SbDrawers
	},
	data(): SmartLockerDataType {
		return {
			dialogState: true,
			axios: null,
			prefs: null,
			libdata: null,
			print_template: "",
			port: null,
			dialogOk: false,
			dialogKo: false,
			patronOk: false,
			adminDialog: false,
			dialogChoose: false,
			dialogReturns: false,
			actualItem: null,
			actualPatron: null,
			actualPatronCell: null,
			actualPatronCells: [],
			patronMode: false,
			adminMode: false,
			APITimeout: 20000,
			adminBarCode: "admin",
			adminModeTimeout: 300,
			UserModeTimeout: 60,
			timeoutVal: 60,
			timeoutMax: 60,
			itemToCheckOut: 0,
			itemCheckedOut: 0,
			itemInError: 0,
			lockerDB: [] as Array<ILockerDBItem>,
			columns: [[]] as Array<Array<ILockerDBItem>>,
			items: {},
			infoMessage: "",
			materialAlreadyPresent: "",
			patronErrorMessage: "",
			patronErrorTitle: "",
			patronCheck: false,
			title: "",
			bigTxtSize: "30pt",
			mediumTxtSize: "20pt",
			smallTxtSize: "15pt",
			libraryName: "",
			template: "standard",
			checkItemStatusOnLoad: true,
			itemStatusOnLoad: 'F',
			maxCheckOutLateDays: 15,
			slideIndex: 0,
			slideTimer: null,
			selectedDbCellId: -1,
			selectedDbCellIdArr: [],
			loading: false,
			doInit: false,
			errorDismissed: true,
			overriddenPatronErrorMessage: "",
			settingsColumns: [],
			keys: '',
			insertItem: false,
			itemsInLoan: [],
			isThereALock: false,
			isThereCardRFID: false,
			returnLockTimerValue: -1,
			isOpenReturnDisabled: false,
			libIsClosed: 2, // 0 - opened; 1 - closed; 2 - not checked
			isPatronChecked: false,
			release: "1.2.11"
		}
	},
	// watch: {
	// 	libIsClosed(isClosed: boolean) {
	//
	// 	}
	// },
	methods: {

		slideTick: function () {
			if (!this.adminMode) {
				let i;
				const slides: HTMLCollection = document.getElementsByClassName("mySlides");
				for (i = 0; i < slides.length; i++) {
					(slides[i] as HTMLElement).style.display = "none";
				}
				this.slideIndex++;
				if (this.slideIndex > slides.length) {
					this.slideIndex = 1
				}
				(slides[this.slideIndex - 1] as HTMLElement).style.display = "block";
			}
		},

		dismiss() {
			if (this.doInit) {
				this.doInit = false;
				this.initUi();
			}
			else {
				this.actualItem = null;
				this.actualPatron = null;
				this.selectedDbCellId = -1;
				this.items = {};
				this.itemInError = 0;
				this.itemToCheckOut = 0;
				this.itemCheckedOut = 0;
				this.resetTimer();
				this.dialogOk = false;
				this.dialogKo = false;
				this.patronOk = false;
				this.infoMessage = "";
				this.materialAlreadyPresent = "";
				this.patronCheck = false;
				this.errorDismissed = true;
				this.overriddenPatronErrorMessage = "";
			}
		},

		initUi: function () {
			this.actualItem = null;
			this.actualPatron = null;
			this.adminDialog = false;
			this.selectedDbCellId = -1;
			this.items = {};
			this.actualPatronCells = [];
			this.itemInError = 0;
			this.itemToCheckOut = 0;
			this.itemCheckedOut = 0;
			this.resetTimer();
			this.dialogOk = false;
			this.dialogKo = false;
			this.patronOk = false;
			this.infoMessage = "";
			this.materialAlreadyPresent = "";
			this.patronCheck = false;
			this.doInit = false;
			this.overriddenPatronErrorMessage = "";
			this.adminMode = false;
			this.slideTimer && clearInterval(this.slideTimer);
			this.slideTimer = setInterval(this.slideTick, 5000);
		},

		timeoutTick: function () {
			this.timeoutVal -= 1;

			if (this.timeoutVal === 0) {
				this.timeoutVal = this.timeoutMax;
				this.initUi();
				nw.Window.get().focus();
				nw.Window.get().reloadIgnoringCache();
			}

			//SelfboxAPI do inventory for all recognized staffs. Emit selbox event, see hidstaffs.js for detailed properties
			sb.checkStaffs();
		},

		resetTimer() {
			this.timeoutVal = this.timeoutMax;
		},

		getPatronCellIdx(patronId: string) {
			const idArr: Array<number> = [];
			for (let i = 0; i < this.lockerDB.length; i++) {
				if (this.lockerDB[i].patronbarcode === patronId) {
					idArr.push(i);
				}
			}
			return idArr;
		},

		getNowStr: function () {
			return moment().format('YYYY-MM-DDTHH:mm');
		},

		getCellItemIndex(cellId: number, barcode: string) {
			let idx = -1;
			const {items} = this.lockerDB[cellId];
			if (items) {
				for (let i = 0; i < items.length; i++) {
					if (items[i].barcode === barcode) {
						idx = i;
						break;
					}
				}
			}
			return idx;
		},

		async loadActualItemOnLocker(celldbidx: number) {

			const record: ILockerDBItem = this.lockerDB[celldbidx];

			this.loading = true;
			await sb.clavisLoadItem(record.col, record.cell, this.actualItem.Barcode, this.actualItem.PatronBarcode);
			await sb.clavisGetSettings<ISlotsConfig>();
			this.loading = false;
			this.infoMessage = "";
			this.materialAlreadyPresent = "";

		},

		async userPick() {
			//TODO disable button

			let errorFound = false;
			const cellCols: Array<Array<number>> = [];
			for (let  i = 0; i < this.selectedDbCellIdArr.length; i++) {
				const cellId = this.selectedDbCellIdArr[i];
				const {items, col, cell, patronbarcode} = this.lockerDB[cellId];
				const resp = await sb.clavisCheckout(col, cell, patronbarcode as string, true);

				if (items && resp?.data?.REPLY?.STATUS === 'OK') {
					this.itemCheckedOut += items.length;
					this.lockerDB[cellId].items = items.map((item: any) => {
						item.status = 'ok';
						return item;
					});

					cellCols.push([col-1, cell-1]);
				}
				else {
					errorFound = true;
					break;
				}
			}

			if (errorFound) {
				this.initUi();
				this.overriddenPatronErrorMessage = `Errore durante il checkout, non è possibile aprire lo sportello,
					contattare la biblioteca per informazioni`;
				this.onErrorRequest();
			}
			else {
				cellCols.forEach((cellCol: Array<number>) => {
					const [col, cell] = cellCol;
					console.log("userPick", col, cell);
					sb.openLock(col, cell);
				});

				// this.actualPatronCells = [];
				this.patronOk = false;
				this.dialogOk = true;
				logger.debug(`userPick patron.code ${this.actualPatron?.Barcode} - items picked up`);
				this.actualItem = null;
			}

		},

		async print() {

			const now = new Date()
			const m = now.getMonth()+1;
			const snow = now.getDate() + "-" + m + "-" + now.getFullYear();

			const tableData: Array<Array<string>> = this.actualPatronCells.map((item) => {
				let dueDate: string | undefined;
				if (this.actualPatron) {
					const currentLoan = this.actualPatron.Loans.find((loan: ILoans) => loan.Barcode === item.barcode);
					dueDate = currentLoan?.DueDate
				}
				return [item.title, dueDate]
			});

			const input = {
				user: this.actualPatron?.Name,
				barcode: this.actualPatron?.Barcode,
				name: this.libdata?.name,
				phone: this.libdata?.phone,
				email: this.libdata?.email,
				website: this.libdata?.website,
				checkout: true,
				cardstatus: false,
				tableData: tableData,
				data: snow
			};

			await sb.print(this.print_template, input);
		},

		openReturnLock() {
			sb.openReturn();

			if (sb.returntimeout > 0) {

				this.returnLockTimerValue = sb.returntimeout/1000;
				this.isOpenReturnDisabled = true;

				const interval = setInterval(() => {
					if (--this.returnLockTimerValue === 0) {
						this.isOpenReturnDisabled = false;
						this.returnLockTimerValue = -1;
						clearInterval(interval);
					}
				}, 1000);

			}
		},

		/*TODO: rollback or manage items checked out when other items having errors + action log*/
		userCancel: function () {
			this.patronOk = false;
		},

		adminCellOpen: function () {
			this.resetTimer();
			const col = this.lockerDB[this.selectedDbCellId].col-1;
			const row = this.lockerDB[this.selectedDbCellId].cell-1;
			console.log("adminCellOpen", col, row);
			sb.openLock(col, row);
			this.actualItem = null;
		},

		adminCellClose() {
			this.resetTimer();

			this.lockerDB = this.lockerDB.map((item: ILockerDBItem) => ({
				...item,
				status: item.status === "disabled" ? "full" : item.status
			}));

			this.columns = this.setDrawersColumns(this.lockerDB);
			this.selectedDbCellId = -1;
			this.actualItem = null;
			this.insertItem = false;
			this.adminDialog = false;
		},

		async adminCellUnload(caller = 'ui'/*celldbidx: number*/) {

			const record: ILockerDBItem = this.lockerDB[this.selectedDbCellId];

			if (!record) {
				this.adminDialog = false;
				this.actualItem = null;
				this.patronErrorTitle = this.$t("message.problem");
				this.patronErrorMessage = this.$t("message.problemmessage");
				this.dialogKo = true;
				return;
			}

			this.loading = true;
			await sb.clavisUnloadItem(record.col, record.cell, record.patronbarcode as string);
			if (caller !== "itemcheckout") {
				await sb.clavisGetSettings<ISlotsConfig>();
			}
			this.loading = false;

			this.infoMessage = "";
			this.materialAlreadyPresent = "";
			this.actualItem = null;
			this.adminCellClose();

		},

		onCancelLoading() {
			this.infoMessage = "";
			this.materialAlreadyPresent = "";
			this.actualItem = null;

			this.lockerDB = this.lockerDB.map((item: ILockerDBItem) => ({
				...item,
				status: item.status === "disabled" ? "full" : item.status
			}));

			this.columns = this.setDrawersColumns(this.lockerDB);
		},

		async onUnloadItem(lockerDbRecord: ILockerDBItem, itemIndex: number) {
			const record: ILockerDBItem = this.lockerDB[this.selectedDbCellId];
			const { patronbarcode } = record;
			const itemsOfPatron = this.lockerDB
				// recupero i cassetti dove l'utente ha libri
				.filter((item: ILockerDBItem) => item.patronbarcode === patronbarcode)
				// individuo il cassetto dove è presente il libro da scaricare
				// e filtro il contenuto del cassetto per quel libro...
				.reduce((acc: Array<ILockerDBItem>, item: ILockerDBItem) => {
					if ((item.id === record.id) && item.items) {
						// ...qui
						item.items = item.items.filter((book: any) =>
							lockerDbRecord.items && book.barcode !== lockerDbRecord.items[itemIndex].barcode);
					}
					return [...acc, item];
				}, []);

			// scarico i libri
			await sb.clavisUnloadItem(record.col, record.cell, record.patronbarcode as string);

			// ricarico i rimanenti ne cassetti in cui erano precedentemente
			await Promise.all(itemsOfPatron.map(async (record: ILockerDBItem) => {
				return record.items && await Promise.all(record.items.map(async (item: any) => {
					return await sb.clavisLoadItem(record.col, record.cell, item.barcode, record.patronbarcode as string);
				}));
			}));

			await sb.clavisGetSettings();
		},
		async onDrawerClick(cell: any) {
			this.resetTimer();
			const index = this.lockerDB.findIndex((item: any) => item.id === cell.id);
			const lockerRecord = this.lockerDB[index];
			this.selectedDbCellId = index;
			// if (this.actualItem !== null && lockerRecord.status === 'empty') {
			if (this.actualItem !== null) {
				logger.debug("load item " + this.actualItem.Barcode + " on cell " + cell.desc);
				await this.loadActualItemOnLocker(index);
				console.log("onDrawerClick", lockerRecord.col-1, lockerRecord.cell-1);
				sb.openLock(lockerRecord.col-1, lockerRecord.cell-1);
			}
			this.adminDialog = true; //this open storaged book dialog
			if (this.actualItem) {
				this.adminCellOpen();
			}
		},
		onServiceDrawerClick(cell: any) {
			const index = this.lockerDB.findIndex((item: any) => item.id === cell.id);
			const lockerRecord = this.lockerDB[index];
			console.log("onServiceDrawerClick", lockerRecord.col-1, lockerRecord.cell-1);
			sb.openLock(lockerRecord.col-1, lockerRecord.cell-1);
		},
		async onInput({type, data}: EventInfoType) {

			console.log("type", type);
			console.log("data", data);

			this.loading = true;
			await sb.clavisGetSettings();
			this.loading = false;

			if (type === 'barcode') {
				//console.log("On Barcode event: ", event);
				logger.debug("EVENT barcode: " + data.value);

				//Evaluate: librarian ID could be used to admin?
				if (data.value === this.adminBarCode) {
					if (this.adminMode) {
						this.adminMode = false;
						logger.debug("ADMIN MODE OFF <<<");
						this.timeoutMax = this.UserModeTimeout;
						this.timeoutVal = this.UserModeTimeout;
						this.slideTimer = setInterval(this.slideTick, 5000);
					} else {
						clearInterval(this.slideTimer as number);
						this.adminMode = true;
						this.timeoutMax = this.adminModeTimeout;
						this.timeoutVal = this.adminModeTimeout;

						logger.debug(">>> ADMIN MODE ON");
					}
				}
				else {
					if (this.adminMode) { // Lettura mentre sono in Admin
						logger.debug("Getting item info " + data.value);
						//SelfboxAPI call clavis rest API. Emit selfbox event, see selfbox.js for detailed properties
						await sb.clavisGetItemStatusSync(data.value);
					} else if (!this.patronCheck) { // Lettura mentre attendo utente
						logger.debug("Getting patron info " + data.value);
						this.patronCheck = true;
						//SelfboxAPI call clavis rest API. Emit selfbox event, see selfbox.js for detailed properties
						this.isPatronChecked = true;
						await sb.clavisGetPatronStatusSync<IPatronStatus>(data.value);
					}
				}
			}//barcode
		},
		async onPatronStatus({type, data}: EventInfoType<IResponse<IPatronStatus>>) {

			console.log("onPatronStatus");
			this.isPatronChecked = false;

			if (type === 'data') {
				console.log("onPatronStatus", data?.REPLY);
				if (data?.REPLY) {
					this.timeoutMax = 60;
					this.timeoutVal = 60;
					this.resetTimer();
					if (data.REPLY.STATUS === "OK") {
						this.actualPatron = data.REPLY.RESULT;
						//logger.debug(this.actualPatron);
						if (this.actualPatron.Status === "A") {

							const dbcellidxArr = this.getPatronCellIdx(this.actualPatron.Barcode);

							this.itemsInLoan = this.actualPatron.Loans
								.filter((item: ILoans) => item.LoanStatus === EnumLoanStatus.IN_PRESTITO);

							if (dbcellidxArr.length > 0) {
								// actualPatronCells.reduce((acc, cell) => [...acc, ...cell.items], [])
								this.actualPatronCells = dbcellidxArr
									.map((index) => this.lockerDB[index])
									.reduce((acc: any, cell: ILockerDBItem) => cell.items ? [...acc, ...cell.items] : acc, []);
								this.selectedDbCellIdArr = dbcellidxArr;

								console.log("actualPatron", this.actualPatron);

								this.patronOk = true;
								this.patronCheck = false;

							}
							else {
								this.actualPatronCells = [];
								this.dialogKo = true;
								this.patronErrorTitle = this.$t("message.problem");
								this.patronErrorMessage = this.$t("message.noitems");
								logger.debug("patronstatus barcode " + this.actualPatron.Barcode + " no items available");
							}

							if (sb.settings.checklibstatus) {

								const libData = await sb.clavisIsLibraryOpen(sb.settings.clavissllibid, true);

								console.log("libData", libData);

								if (libData?.data?.REPLY) {

									const { STATUS, RESULT } = libData.data.REPLY;
									console.log("RESULT", RESULT);
									this.libIsClosed = +!(STATUS === "OK" && RESULT === "OPEN");
									console.log("libIsClosed", this.libIsClosed);
								}


							}
							else {
								this.libIsClosed = 2;
							}
						}
						else {
							this.overriddenPatronErrorMessage = this.$t("message.user") + " " + this.$t("message.notenabled");
							console.log("this.overriddenPatronErrorMessage", this.overriddenPatronErrorMessage);
						}
					}
					else {
						this.actualPatron = null;
					}
				}//undefined
			}
		},
		onItemStatus({type, data}: EventInfoType) {
			//1040000683895
			if (type === 'data') {
				if (data.REPLY?.RESULT) {
					this.resetTimer();

					this.actualItem = data.REPLY.RESULT;
					this.insertItem = true;
					console.log("this.actualItem", this.actualItem);
					const {ActualLibrary, Barcode, Title, PatronBarcode, LoanStatus} = this.actualItem;

					const disableAll = this.lockerDB.some((item: ILockerDBItem) => item.items && item.items.some(({barcode}) => barcode === this.actualItem.Barcode));

					if (disableAll) {
						this.lockerDB = this.lockerDB.map((item: ILockerDBItem) => ({
							...item,
							status: "disabled"
						}));
					}
					else {

						this.lockerDB = this.lockerDB.map((item: ILockerDBItem) => {
							if ((item?.patronbarcode !== PatronBarcode)) {
								return {
									...item,
									status: "disabled"
								}
							}
							return item;
						});
					}

					this.columns = this.setDrawersColumns(this.lockerDB);

					if (data.REPLY.STATUS === "OK") {

						// show info message
						if (this.checkItemStatusOnLoad && this.itemStatusOnLoad === 'F'
							&& ActualLibrary !== sb.settings.clavissllibid) {
							this.infoMessage = `Errore dell'esemplare ${Barcode}: Biblioteca non corretta`;
							this.materialAlreadyPresent = "";
							logger.debug(`item.id ${Barcode} item.actlibid ${ActualLibrary}
								not match with applibid ${sb.settings.clavissllibid}`);
							return;
						}

						let errorMessage = "";
						if (this.checkItemStatusOnLoad && LoanStatus !== this.itemStatusOnLoad) {
							errorMessage = "Errore dell'esemplare " + Barcode + " : ";

							if (this.itemStatusOnLoad === 'F') {
								errorMessage += " Esemplare non in pronto al prestito ";
							} else {
								errorMessage += " stato esemplare non corretto ";
							}

							logger.debug(
								"item.id " + Barcode + " item.loanstatus " + LoanStatus + " not ready to loan (" + LoanStatus + ")");
							this.infoMessage = errorMessage;
							this.materialAlreadyPresent = "";

							// var errorMessage = "Errore dell'esemplare " + data.REPLY.RESULT.Barcode + " : ";
							// if (this.itemStatusOnLoad === 'F') {
							// 	errorMessage += " Esemplare non in pronto al prestito ";
							// } else {
							// 	errorMessage += " stato esemplare non corretto ";
							// }
							// logger.debug("item.id " + data.REPLY.RESULT.Barcode + " item.loanstatus " + data.REPLY.RESULT.LoanStatus + " not ready to loan (" + this.itemStatusOnLoad + ")");
							// this.infoMessage = errorMessage;

							return;
						}

						// if (this.checkItemStatusOnLoad) {
						// 	if (LoanStatus === 'F') {
						// 		errorMessage += " Esemplare non in pronto al prestito ";
						// 	} else {
						// 		errorMessage += " stato esemplare non corretto ";
						// 	}
						//
						// 	this.infoMessage = errorMessage;
						// 	this.materialAlreadyPresent = "";
						// 	return;
						// }

						console.log("infoMessage", this.infoMessage);

						logger.debug("item " + Barcode + " " + Title +
							" set. Searching for assigned cell...");

						this.infoMessage = "Scegli un cassetto (" + Barcode + ")";
						const patronIdexes = this.lockerDB
							.reduce((acc, {id, patronbarcode}) => {
								if (this.actualItem.PatronBarcode === patronbarcode) {
									acc.push(id);
								}
								return acc
							}, [] as Array<number|undefined>);

						if (patronIdexes.length > 0) {
							this.materialAlreadyPresent = `${this.actualItem.PatronBarcode} ha già materiale nei cassetti ${patronIdexes.join(', ')}`;
						}
						else {
							this.materialAlreadyPresent = `Nessun materiale presente per ${this.actualItem.PatronBarcode}`;
						}
						logger.debug("no cell found for " + Barcode + " " + Title);

					}
					else {
						this.overriddenPatronErrorMessage = "Errore ricerca esemplare " + Barcode + " (" + data.REPLY.ERRORINFO + ")";
					}
				}
			}
		},
		onLock({action, type, data}: EventInfoType) {
			if (action === 'lock' && type === 'open' && data !== 'open') {
				logger.error("Bad response opening lock ");
			}
		},
		onGetTreSource({type, data}: EventInfoType) {
			if ("result" in data && "status" in data["result"] && data["result"]["status"] === 'ack') {
				if ('conf' in data["result"]["data"]) {
					console.log("Clavis resource configuration from clavis: " + data["result"]["data"]["conf"]);
				}
			}
		},
		onSetTreSource({type, data}: EventInfoType) {
			console.log(JSON.stringify(data));
			if ("result" in data && "status" in data["result"] && data["result"]["status"] === 'ack') {
				console.log("Clavis resource set: SUCCESS");
			}
		},
		onSbError({action, source, type, data}: EventInfoType) {
			const msg = "selfbox event handler - action: " + action + " source: " + source + " errdesc: " + data;
			console.log(msg);
			logger.error(msg);
			if (action !== 'lock') {
				this.dialogKo = true;
				this.patronErrorMessage = this.$t("message.exception");
			}
		},
		onErrorRequest(eventInfo: EventInfoType = {}) {
			setTimeout(() => {
				if (!this.dialogKo) {

					if (this.isPatronChecked) {
						this.actualPatron = null;
						this.isPatronChecked = false;
					}

					this.dialogKo = true;
					this.errorDismissed = false;
					console.log("eventInfo", eventInfo);
					this.patronErrorMessage = this.overriddenPatronErrorMessage ||
						(eventInfo.data.REPLY?.ERRORINFO) || this.$t("message.exception");
				}
			})
		},
		async onGetSettings(eventInto: EventInfoType) {

			this.doInit = !!eventInto.data.doInit

			const remoteSettings = eventInto.data;

			console.log("remoteSettings", remoteSettings);

			if (eventInto.data.doInit) {
				sb.init(remoteSettings);
			}

			this.settingsColumns = remoteSettings.REPLY.RESULT.config.settings.columns;

			console.log("settingsColumns", this.settingsColumns);
			const cellsStatus: Array<ICell> = remoteSettings.REPLY.RESULT.cellStatus.cells;

			this.lockerDB = this.setLockerDB(this.settingsColumns, cellsStatus);
			this.columns = this.setDrawersColumns(this.lockerDB);
		},
		async onChekoutItems(eventInto: EventInfoType) {
			const response = eventInto.data;
			if (eventInto.data && response.REPLY.STATUS === 'OK') {
				this.actualPatronCell.items = this.actualPatronCell.items.map((item: any) => {
					item.status = 'ok';
					return item;
				});

				this.resetTimer();
				// await this.adminCellUnload('itemcheckout');
				this.itemCheckedOut = response.REPLY.RESULT.length;
				this.patronOk = false;
				this.dialogOk = true;
				logger.debug("userPick patron.code " + this.actualPatron?.Barcode + " - items picked up");

				const {col, cell} = this.lockerDB[this.selectedDbCellId];
				console.log("onChekoutItems", col-1, cell-1);
				sb.openLock(col-1, cell-1);
				this.actualItem = null;

				// this.initUi();
			}
			else {
				this.initUi();
				this.overriddenPatronErrorMessage = `Errore durante il checkout, non è possibile aprire lo sportello,
					contattare la biblioteca per informazioni`;
				this.onErrorRequest();
			}
		},

		onSelfBox(eventinfo: EventInfoType) {

			if (!this.errorDismissed) {
				return;
			}

			const objHandler: any = {
				"input": this.onInput,
				"patronstatus": this.onPatronStatus,
				"itemstatus": this.onItemStatus,
				// "itemcheckout": this.onItemCheckout,
				"lock": this.onLock,
				"getresource": this.onGetTreSource,
				"setresource": this.onSetTreSource,
				"getSettings": this.onGetSettings,
				"loadItem": (eventinfo: EventInfoType) => console.log("loadItem", eventinfo),
				"unloadItem": (eventinfo: EventInfoType) => console.log("unloadItem", eventinfo),
				// "chekoutItems": this.onChekoutItems,
				"chekoutItems": (eventinfo: EventInfoType) => console.log("loadItem", eventinfo),
				"error": this.onSbError,
				"errorRequest": this.onErrorRequest
			};

			objHandler[eventinfo.action](eventinfo);
		},
		setLockerDB(columns: Array<IColumn>, cellsStatus: Array<ICell>): Array<any> {
			console.log("lol");
			let cumulativeLength = 0;
			let slots = 0;
			// let id = 0;
			const lockerDB: Array<ILockerDBItem> = [];
			let idService = -1;

			for (let i = 0; i < columns.length; i++) {
				const column = columns[i];
				const length = column.cellslockmap.length;
				slots += length;

				const cellsUse = column.cellsuse;
				for (let j = 0; j < length; j++) {

					const cellStatus = cellsStatus[cumulativeLength+j];

					const cellNumber = column.cellslockmap[j];
					const celluse = column.cellsuse[j];
					const record: ILockerDBItem = {
						id: celluse ? idService-- : cellStatus?.lockID,
						col: i+1,
						cell: j+1,
						desc: cellNumber,
						use: celluse,
						status: "empty",
						patronbarcode: cellStatus?.patron?.barcode,
						days: cellStatus?.loadDays,
						dtload: null,
						dtupdt: null,
						dtretr: null,
						items: cellStatus?.items,
						cellStatus,
						position: [i,j]
					};
					lockerDB.push(record);
					// id++;
				}
				// cumulativeLength += (cellsUse.some((item: number) => !item)) ? length-1 : 0;
				cumulativeLength += length;
			}

			console.log("lockerDB", lockerDB);

			return [...lockerDB];
		},
		setDrawersColumns(lockerDB: Array<ILockerDBItem>): Array<Array<ILockerDBItem>> {
			// let currentCol = 0;
			// let realCurrentCol = 0;
			const columns: Array<Array<ILockerDBItem>> = [[]];
			const lol: any = {}

			const allDisabled = this.actualItem && lockerDB.some(cell => cell.items && cell.items.some(item => item.barcode === this.actualItem.Barcode));

			let onlyDrawers: Array<ILockerDBItem> ;

			if (allDisabled) {
				onlyDrawers = lockerDB
					.map((itemFiltered: ILockerDBItem) => {
						if (!lol[itemFiltered.col]) {
							lol[itemFiltered.col] = 0
						}
						lol[itemFiltered.col]++;
						let status = 'empty';
						const {cellStatus} = itemFiltered;
						if (cellStatus) {
							status = "disabled";
						}
						itemFiltered.status = status;
						return itemFiltered;
					});
			}
			else {
				onlyDrawers = lockerDB
					// normalize column's number, the number of columns must not consider the service column
					.map((itemFiltered: ILockerDBItem) => {

						if (!lol[itemFiltered.col]) {
							lol[itemFiltered.col] = 0
						}
						lol[itemFiltered.col]++;

						const {items = [], cellStatus} = itemFiltered;

						let status = 'empty';
						if (cellStatus) {
							const { patron, items } = cellStatus;
							if (items.length > 0) {
								status = "full"
								if (this.actualItem) {
									status = (patron.barcode === this.actualItem.PatronBarcode) ? "full" : "disabled";
								}
							}
						}
						itemFiltered.status = status;
						// itemFiltered.col -= (itemFiltered.col - currentCol);
						return itemFiltered;
					});
			}

			console.log("onlyDrawers", onlyDrawers);

			const maxNumberRows: number = Math.max(...Object.keys(lol).map(key => lol[key]));
			const colNumbers = Object.keys(lol).map(key => parseInt(key));
			const drawersSplittedByColumn = colNumbers.reduce((acc, num) => {
				return [...acc, onlyDrawers.filter((item) => item.col === num)];
			}, [] as Array<Array<ILockerDBItem>>);

			for (let i = 0; i < maxNumberRows; i++) {
				drawersSplittedByColumn.forEach((col) => {
					if (!columns[i]) {
						columns.push([]);
					}
					columns[i].push(col[i] || { empty: true });
				})
			}

			console.log("columns", columns);

			return columns;
		}
	},
	mounted: async function() {

		sb.evem.on('selfbox', this.onSelfBox);

		this.loading = true;
		const remoteSettings: any = await sb.clavisGetSettings();
		this.loading = false;

		const { settings, prefs, libdata, print_template }: IConfing = remoteSettings.REPLY.RESULT.config;
		this.prefs = prefs;
		this.libdata = libdata;
		this.print_template = print_template;

		this.APITimeout = prefs?.APITimeout || 20000;
		this.adminBarCode = prefs?.AdminBarCode || "admin";
		this.adminModeTimeout = prefs?.AdminModeTimeout || 300;
		this.UserModeTimeout = prefs?.UserModeTimeout || 60;
		this.timeoutVal = prefs?.UserModeTimeout || 60;
		this.timeoutMax = prefs?.UserModeTimeout || 60;
		this.title = prefs?.Title;
		this.bigTxtSize = prefs?.BigTxtSize || "30pt";
		this.mediumTxtSize = prefs?.MediumTxtSize || "20pt";
		this.smallTxtSize = prefs?.SmallTxtSize || "15pt";
		this.libraryName = prefs?.LibraryName;
		// this.libraryName = `Prima riga <br><span class="tr">Seconda riga</span>`;
		this.template = prefs?.Template || 'standard';
		this.checkItemStatusOnLoad = prefs?.CheckItemStatusOnLoad === undefined ? true : prefs.CheckItemStatusOnLoad;
		this.itemStatusOnLoad = prefs?.ItemStatusOnLoad || 'F';
		this.maxCheckOutLateDays = prefs?.MaxCheckOutLateDays || 15;

		sb.init(settings);

		console.log("checklibstatus", sb.settings.checklibstatus);

		this.isThereALock = !!sb.settings.returnserialport;
		this.isThereCardRFID = sb.settings.patronstaffserial &&
			sb.settings.patronstaffserial !== "0" && sb.settings.patronstaffserial !== "no";

		if (sb.settings.checklibstatus) {

			const libData = await sb.clavisIsLibraryOpen(sb.settings.clavissllibid, true);

			if (libData?.data?.REPLY) {
				const { STATUS, RESULT } = libData.data.REPLY;
				console.log("RESULT", RESULT);
				this.libIsClosed = +!(STATUS === "OK" && RESULT === "OPEN");
			}
		}
		else {
			this.libIsClosed = 2;
		}

		console.log("libIsClosed", this.libIsClosed);

		logger.debug("Smartlocker client starting. Connecting to ", window.location.href);
		(window as any).keyBuffer = '';
		/* eslint-disable */
		const that = this;
		window.addEventListener('keypress', function (e) {
			// String.fromCharCode(e.keyCode);


			console.log("e", e);
			console.log("(window as any).keyBuffer)1", (window as any).keyBuffer);
			if (e.key === 'Enter') {
				console.log("(window as any).keyBuffer", (window as any).keyBuffer);
				sb.parseInput((window as any).keyBuffer.trim());
				(window as any).keyBuffer = '';
			}
			else if (e.key === 'Shift') {
				(window as any).keyBuffer += '';
			}
			else {
				//WARN: UPPERCASE ... ?
				that.keys += e.key;
				if( /[a-zA-Z0-9 ]/.test(e.key) ) (window as any).keyBuffer += e.key;
			}
		});

		setInterval(this.timeoutTick, 1000);
		this.slideTimer = setInterval(this.slideTick, 5000);
	}
})
