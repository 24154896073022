import {defineComponent} from "vue";
import SbFooter from "@/components/sb-footer/sb-footer.vue";
import SbNavBar from "@/components/sb-nav-bar/sb-nav-bar.vue";

const logger = sb.logger;

export default defineComponent({
	name: "cmp-reso24",
	components: {
		"sb-footer": SbFooter,
		"sb-nav-bar": SbNavBar,
	},
	data() {
		return {
			loading: false,
			patron: "",
			openTimer: -1,
			staffinterval: -1,
			release: "lts"
		}
	},
	methods: {
		appendMsg(msg: string): void {
			//const iev: HTMLInputElement = document.getElementById('iev') as HTMLInputElement;
			//if (!iev) return;
			//const oldval = iev.value;
			//if(msg === 'reset'){
				//iev.value = '';
			//}else{
				//iev.value = oldval + msg + "\n";
			//}

			logger.debug(msg);
		},
		async onSelfBox(eventinfo: EventInfoType) {

			if(eventinfo.action !== 'getSettings'){ logger.debug(JSON.stringify(eventinfo)); }

			if(eventinfo.action === 'input'){
				if(eventinfo.type === 'barcode'){
					logger.debug("Ev input barcode: " + eventinfo.data.value + "\n");

					if(eventinfo.data.value === 'relinfo'){
						logger.debug(sb.getReleaseInfo());
						return;
					}

					let libIsClosed = true;
					let libData = null;

					if(sb.settings.checklibstatus){
						try{
							libData = await sb.clavisIsLibraryOpen(sb.settings.clavissllibid, true);
							if(libData){
								if(libData.data.REPLY.STATUS === "OK" && libData.data.REPLY.RESULT === "OPEN") libIsClosed = false;
							}
						}catch(error){
							logger.error("UNABLE TO CHECK LIBRARY STATUS");
							logger.error(error);
						}
					}

					if (libIsClosed) {
						if( !this.patron ){
							//this.patron = eventinfo.data.value;
							if( eventinfo.source === 'keyboard' || (eventinfo.source === 'staff' && eventinfo.data.type === 'CARD') ){
								this.patron = eventinfo.data.value;
								logger.debug("Get patron status "+eventinfo.data.value);
								const patron = await sb.clavisGetPatronStatusSync(eventinfo.data.value);
								logger.debug(JSON.stringify(patron));
							}
						}else{
							logger.debug( "already reading. wait for an item...\n" );
							if( eventinfo.source === 'staff' && eventinfo.data.type === 'ITEM' ){
								//devid is mandatory
								if( eventinfo.devid === "" ){
									logger.error("*** ERROR: staff item reader without serial number ***");
								}

								if( 'patronstaffserial' in sb.settings ){
									if( sb.settings.patronstaffserial === ""){
										logger.error("*** ERROR: settings.patronstaffserial is empty. Which is the book reader? Unable to perform an item check in ***");
									}else{

										//sb.settings.patronstaffserial may be no or serialnumber. Both must be different from item reader serial number
										if( sb.settings.patronstaffserial != eventinfo.devid ){
											logger.debug("Item checkin "+eventinfo.data.value);
											const ckin = await sb.clavisItemCheckInSync(eventinfo.data.value, true, true);
											logger.debug(JSON.stringify(ckin));
										}
									}
								}
							}
						}
					}else{
						logger.debug("Library is open, do nothing");
						//this.relayDo('err');
						//turn on second relay only to signal error to user
						sb.openReturn('2');
					}
				}
			}

			if(eventinfo.action === 'patronstatus'){
				if(eventinfo.type === 'data'){
					//this.relayDo('open');
					//turn on second both relay
					sb.openReturn();
				}
			}

			if(eventinfo.action === 'errorRequest'){
				if(eventinfo.type === 'error'){
					//console.log(eventinfo);
					//this.relayDo('err');
					//turn on second relay only to signal error to user
					sb.openReturn('2');
				}
			}

			/*Reset current patron on return lock open timeout*/
			if(eventinfo.action === 'lock'){
				if(eventinfo.type === 'close'){
					this.patron = "";
				}
			}
		},
		onKeypress(e: KeyboardEvent) {
			if (e.key === 'Enter') {
				const inval = (window as any).keyBuffer.trim();
				logger.debug("Keyb in: " + inval + "\n");
				sb.parseInput(inval);
				(window as any).keyBuffer = '';
			} else {
				if (/[a-zA-Z0-9]/.test(e.key)) (window as any).keyBuffer += e.key;
			}
		}
	},
	mounted() {

		(window as any).keyBuffer = '';
		window.addEventListener('keypress', this.onKeypress);

		sb.init({});
		this.release = sb.release;
		sb.closeReturn();
		this.patron="";

		/*If have staff, check it every x msec*/
		this.staffinterval = setInterval(function(){
			/*
			* SelfboxAPI
			* Do inventory for all recognized staffs.
			* Emit selbox event.
			*/
			sb.checkStaffs();

			//Recall focus to avoid other windows to trap input
			if(sb.settings.usefocustip){
				nw.Window.get().focus();
			}
		}, 1000);

		//Trap hardware events from above
		sb.evem.on('selfbox', this.onSelfBox);

	},
	unmounted() {
		clearInterval(this.staffinterval);
		window.removeEventListener('keypress', this.onKeypress);
	}
});
