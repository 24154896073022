import {defineComponent} from "vue";
// import moment from "moment";
import SbFooter from "@/components/sb-footer/sb-footer.vue";
import SbCarousel from "@/components/sb-carousel/sb-carousel.vue";
import SbNavBar from "@/components/sb-nav-bar/sb-nav-bar.vue";

const logger = sb.logger;

export default defineComponent({
	name: "cmp-reso24-monitor",
	components: {
		"sb-footer": SbFooter,
		"sb-carousel": SbCarousel,
		"sb-nav-bar": SbNavBar
	},
	data() {
		return {
			APITimeout: 20000,
			actualPatron: null,
			carouselArr: [],
			dialogCountdown: false,
			dialogOk: false,
			dialogKo: false,
			errorDismissed: true,
			libdata: null,
			libraryName: "",
			imgCountdown: "",
			isOpenReturnDisabled: false,
			isPatronChecked: false,
			isThereALock: false,
			isThereCardRFID: false,
			libIsClosed: 2, // 0 - opened; 1 - closed; 2 - not checked
			loading: false,
			overriddenPatronErrorMessage: "",
			patronErrorTitle: "",
			patronCheck: false,
			patronOk: false,
			prefs: null,
			print_template: "",
			originalReturnLockTimerValue: 0,	// delay of the return lock
			release: "1.0.0",
			returnLockTimerValue: -1, // second remained after the start of the countdown
			slideIndex: 0,
			slideTimer: null,
			textCountdown: "",
			timeoutMax: 60,
			timeoutVal: 60,
			title: "",
			UserModeTimeout: 60
		} as any
	},
	methods: {
		initUi: function () {
			this.resetTimer();
			this.actualPatron = null;
			this.dialogOk = false;
			this.dialogKo = false;
			this.dialogCountdown = false;
			this.patronOk = false;
			this.patronErrorTitle = "";
			this.patronCheck = false;
			this.errorDismissed = true;
			this.overriddenPatronErrorMessage = "";
			this.slideIndex = 0,
			this.slideTimer && clearInterval(this.slideTimer);
			this.slideTimer = setInterval(this.slideTick, 5000);
		},
		timeoutTick: function () {
			this.timeoutVal -= 1;

			if (this.timeoutVal === 0) {
				this.timeoutVal = this.timeoutMax;
				this.initUi();
				nw.Window.get().focus();
				nw.Window.get().reloadIgnoringCache();
			}

			//SelfboxAPI do inventory for all recognized staffs. Emit selbox event, see hidstaffs.js for detailed properties
			sb.checkStaffs();
		},
		resetTimer() {
			this.timeoutVal = this.timeoutMax;
		},
		slideTick: function () {
			let i;
			const slides: HTMLCollection = document.getElementsByClassName("mySlides");

			console.log("slides", slides);
			for (i = 0; i < slides.length; i++) {
				(slides[i] as HTMLElement).style.display = "none";
			}
			this.slideIndex++;
			if (this.slideIndex > slides.length) {
				this.slideIndex = 1
			}
			(slides[this.slideIndex - 1] as HTMLElement).style.display = "block";
		},
		openReturnLock() {
			sb.openReturn();

			if (sb.returntimeout > 0) {

				this.dialogCountdown = true;

				this.returnLockTimerValue = sb.returntimeout/1000;
				this.isOpenReturnDisabled = true;

				setTimeout(() => {
					this.animateTimeoutBar();
				});

				const interval = setInterval(() => {
					if (--this.returnLockTimerValue === 0) {
						this.isOpenReturnDisabled = false;
						this.returnLockTimerValue = -1;
						this.dialogCountdown = false;
						clearInterval(interval);
					}
				}, 1000);

			}
		},
		/**
		 * close everything and restart the frontend
		 */
		dismiss() {
			this.initUi();
		},

		/* ################################################################################################# */
		/* ######################################## EVENT HANDLERS ######################################### */
		/* ################################################################################################# */

		/**
		 * Handler of event "input".
		 * Manage the response of the event input, in this particular case, manage the response from a bar code reader
		 *
		 * @param type
		 * @param data
		 */
		async onInput({type, data}: EventInfoType) {

			this.loading = true;
			await sb.clavisGetSettings();
			this.loading = false;

			if (type === 'barcode') {

				/******************************************************************************/
				/* TODO: put here somewhere the "clavisGetItemStatusSync" request eventually, */
				/*	if the status of the single book is needed													      */
				/******************************************************************************/

				logger.debug("EVENT barcode: " + data.value);

				if (!this.patronCheck) {
					logger.debug("Getting patron info " + data.value);
					this.patronCheck = true;
					this.isPatronChecked = true;
					// emit "partronstatus", handler "onPatrolStatus"
					await sb.clavisGetPatronStatusSync<IPatronStatus>(data.value);
				}
			}

		},
		/**
		 * Handler of event "patronstatus".
		 * Check if a user is a valid one and (probably in the future) will check the status of items to returns.
		 *
		 * @param type
		 * @param data
		 */
		async onPatronStatus({type, data}: EventInfoType<IResponse<IPatronStatus>>) {

			this.isPatronChecked = false;

			// early return if unrecognized "type" property, if malformed or not existent reply
			if (type !== 'data' || !data || !data.REPLY) {
				return;
			}

			this.actualPatron = data.REPLY.RESULT;

			// is a valid user?
			if (this.actualPatron.Status === "A") {

				/*****************************************************************/
				/* TODO: put here, somewhere, the management of items to returns */
				/*****************************************************************/

				this.patronOk = true;
				this.patronCheck = false;

				// check if library status exists in settings and if is important
				if (sb.settings.checklibstatus) {

					const libData = await sb.clavisIsLibraryOpen(sb.settings.clavissllibid, true);

					// check if is library open ("libIsClosed" is 0 or 1, respectively if closed or open)
					if (libData?.data?.REPLY) {
						const {STATUS, RESULT} = libData.data.REPLY;
						this.libIsClosed = +!(STATUS === "OK" && RESULT === "OPEN");
					}
				}
				// nobody cares of the library status (hence "libIsClosed" is 2, check the initialization of the property)
				else {
					this.libIsClosed = 2;
				}

			} // (this.actualPatron.Status === "A")
			// no it's not a valid user, Karen
			else {
				this.patronErrorTitle = this.$t("message.problem");
				this.overriddenPatronErrorMessage = this.$t("message.user") + " " + this.$t("message.notenabled");
				console.log("this.overriddenPatronErrorMessage", this.overriddenPatronErrorMessage);
			}// (this.actualPatron.Status !== "A")

		},
		/**
		 * Handler of event "getSettings".
		 * Reinitialize the frontend and the selfbox class.
		 *
		 * TODO: probably the initialization in the mounted should be moved here
		 *
		 * @param eventInto
		 */
		async onGetSettings(eventInto: EventInfoType) {
			const remoteSettings = eventInto.data;
			console.log("remoteSettings", remoteSettings);
			if (this.doInit) {
				sb.init(remoteSettings);
			}
		},
		/**
		 * Handler of event "lock".
		 * Fired on actions performed on the return drawer lock, used only to log error eventually
		 *
		 * @param action
		 * @param type
		 * @param data
		 */
		onLock({action, type, data}: EventInfoType) {
			if (action === 'lock' && type === 'open' && data !== 'open') {
				logger.error("Bad response opening lock ");
			}
		},
		/**
		 * Handler of event "error".
		 * Handling generic error from selfbox class
		 *
		 * FIXME: probably useless, some tests needed
		 *
		 * @param action
		 * @param source
		 * @param type
		 * @param data
		 */
		onSbError({action, source, type, data}: EventInfoType) {
			const msg = "selfbox event handler - action: " + action + " source: " + source + " errdesc: " + data;
			console.log(msg);
			logger.error(msg);
			if (action !== 'lock') {
				this.dialogKo = true;
				this.patronErrorMessage = this.$t("message.exception");
			}
		},
		/**
		 * Handler of event "errorRequest".
		 * Handling errors from the http request performed in the selfbox class
		 *
		 * @param eventInfo
		 */
		onErrorRequest(eventInfo: EventInfoType = {}) {
			setTimeout(() => {
				if (!this.dialogKo) {

					if (this.isPatronChecked) {
						this.actualPatron = null;
						this.isPatronChecked = false;
					}

					this.dialogKo = true;

					this.errorDismissed = false;
					this.patronErrorMessage = this.overriddenPatronErrorMessage ||
						(eventInfo.data.REPLY?.ERRORINFO) || this.$t("message.exception");
				}
			})
		},

		/* ################################################################################################## */
		/* ######################################## /EVENT HANDLERS ######################################### */
		/* ################################################################################################## */

		/**
		 * Hub for the selfbox events
		 *
		 * @param eventinfo
		 */
		onSelfBox(eventinfo: EventInfoType) {
			if (!this.errorDismissed) {
				return;
			}

			const objHandler: any = {
				"input": this.onInput,
				"patronstatus": this.onPatronStatus,
				"lock": this.onLock,
				"getSettings": this.onGetSettings,
				"error": this.onSbError,
				"errorRequest": this.onErrorRequest
			};

			objHandler[eventinfo.action](eventinfo);
		},
		animateTimeoutBar() {

			const frame = () => {
				if (width >= 100) {
					clearInterval(id);
				} else {
					width += 100/(sb.returntimeout/1000);
					elem.style.width = width + "%";
				}
			}

			const elem: HTMLElement = this.$refs['countdown-progress'];
			elem.style.width = "0%";
			let width = 0;
			const id = setInterval(frame, 1000);

		}
	},
	mounted: async function() {

		sb.evem.on('selfbox', this.onSelfBox);

		/* retrieve settings and preferences (probably sould be the same object) from remote,
		if the response contain a property that exists in local, the remote one wins over the local */
		this.loading = true;
		const remoteSettings: any = await sb.clavisGetSettings();
		this.loading = false;

		const { settings, prefs, libdata, print_template }: IConfing = remoteSettings.REPLY.RESULT.config;

		this.prefs = prefs;
		this.libdata = libdata;
		this.print_template = print_template;

		this.APITimeout = prefs?.APITimeout || 20000;
		this.timeoutVal = prefs?.UserModeTimeout || 30;
		this.timeoutMax = prefs?.UserModeTimeout || 60;
		this.title = prefs?.Title;
		this.libraryName = prefs?.LibraryName;

		// eventually merge the remote settings with the local ones and init selfbox class
		sb.init(settings);

		if (sb.settings.carouselArr) {
			this.carouselArr = sb.settings.carouselArr;
		}

		this.imgCountdown = sb.settings.returntimeoutImg;
		this.textCountdown = sb.settings.returntimeoutText

		this.originalReturnLockTimerValue = sb.returntimeout/1000;
		this.isThereALock = !!sb.settings.returnserialport;
		this.isThereCardRFID = sb.settings.patronstaffserial &&
			sb.settings.patronstaffserial !== "0" && sb.settings.patronstaffserial !== "no";

		if (sb.settings.checklibstatus) {

			const libData = await sb.clavisIsLibraryOpen(sb.settings.clavissllibid, true);

			if (libData?.data?.REPLY) {
				const { STATUS, RESULT } = libData.data.REPLY;
				console.log("RESULT", RESULT);
				this.libIsClosed = +!(STATUS === "OK" && RESULT === "OPEN");
			}
		}
		else {
			this.libIsClosed = 2;
		}

		console.log("libIsClosed", this.libIsClosed);

		logger.debug("Smartlocker client starting. Connecting to ", window.location.href);
		(window as any).keyBuffer = '';
		/* eslint-disable */
		const that = this;
		window.addEventListener('keyup', function (e) {
			// String.fromCharCode(e.keyCode);
			if (e.key === 'Enter') {
				sb.parseInput((window as any).keyBuffer.trim());
				(window as any).keyBuffer = '';
			}
			else if (e.key === 'Shift') {
				(window as any).keyBuffer += '';
			}
			else {
				//WARN: UPPERCASE ... ?
				that.keys += e.key;
				if( /[a-zA-Z0-9]/.test(e.key) ) (window as any).keyBuffer += e.key;
			}
		});

		setInterval(this.timeoutTick, 1000);
		this.slideTimer = setInterval(this.slideTick, 5000);
	}
})
